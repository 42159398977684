<template>
    <div class="text-center">
        <h1>Oops!</h1>
        <h2>404 Not Found</h2>
        <div class="error-details">
            Sorry, an error has occurred, Requested page not found!
        </div>
        <div class="my-3">
            <router-link class="mr-3" to="/"><i class="fas fa-home"></i> Go Home</router-link>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'notfound'
    }
</script>
<style scoped>
    a {
        color: #42b983;
    }

</style>
